<template>
  <v-img :aspect-ratio="aspectRatio" v-if="src" class="jumbotron-image" :src="src">
    <slot />
  </v-img>
  <v-responsive v-else :aspect-ratio="aspectRatio">
    <v-sheet :color="color" width="100%" class="fill-height">
      <slot />
    </v-sheet>
  </v-responsive>
</template>

<script>
export default {
  name: 'Jumbotron',
  props: {
    color: String,
    src: {
      type: String
    },
    'aspect-ratio': {
      type: Number,
      default () {
        return 22 / 9
      }
    }
  }
}
</script>
