<template>
  <div class="d-flex flex-column pa-0 ma-0">
    <jumbotron :aspect-ratio="4" src="/img/backgrounds/blogs-background.jpg">
      <v-container class="fill-height pa-0 ma-0 white--text">
        <v-row class="fill-height" justify="start" align="end">
          <v-col :cols="12">
            <h1
              :class="{
                'display-2': isOnPC,
                'display-1': !isOnPC,
                'px-4': isOnPC,
                'px-2': !isOnPC
              }"
              style="text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7)"
            >Articles and Posts</h1>
          </v-col>
        </v-row>
      </v-container>
    </jumbotron>
    <v-container>
      <v-row>
        <v-col cols="12">
          <p>In this section, we provide articles that might be of interest such as news, coverage of recent events, travel guide, and other information.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(blog, n) in blogs" :key="`${n}${JSON.toString(blog)}`"
          xs="12" sm="6" md="4"
        >
          <v-card :to="`/articles/${blog.id}/${blog.url ? blog.url : ''}`">
            <v-img
              :src="blog.thumbnailURL"
              class="white--text align-end"
              gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.7)"
            >
              <v-card-title class="text-no-wrap">{{ blog.title }}</v-card-title>
              <v-card-subtitle class="white--text">{{ blogsHumanFriendlyTimePosted[n] }}</v-card-subtitle>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" v-intersect="populateList">
        <v-progress-circular v-show="loading" indeterminate />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Jumbotron from '../components/Jumbotron'
import firebase from '../plugins/firebase'

import moment from 'moment'

export default {
  components: {
    jumbotron: Jumbotron
  },

  data () {
    return ({
      blogs: [],
      loading: false,
      finalHit: false,
      lastBlogDocRef: null
    })
  },
  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    blogsHumanFriendlyTimePosted () {
      return Array.from(
        this.$data.blogs,
        blog => moment(blog.dateModified.toDate()).fromNow()
      )
    }
  },

  methods: {
    getBlogList (limit = 6) {
      return new Promise((resolve, reject) => {
        const db = firebase.firestore()

        var query = db.collection('articles/').orderBy('dateModified', 'desc').limit(limit)
        if (this.$data.lastBlogDocRef) query = query.startAfter(this.$data.lastBlogDocRef)

        query.get().then(collectionSnapshot => {
          var o = {
            results: collectionSnapshot.docs.map(doc => Object.assign({}, doc.data(), { id: doc.id })),
            lastDocReference: collectionSnapshot.docs[collectionSnapshot.docs.length - 1]
          }

          resolve(o)
        }).catch(e => { reject(e) })
      })
    },

    async populateList () {
      // Check if already fetched the last in the list before fetching
      if (!this.$data.finalHit && !this.$data.loading) {
        this.$data.loading = true

        const fetchedEntries = await this.getBlogList()

        let existingEntries = this.$data.blogs
        existingEntries = existingEntries.concat(fetchedEntries.results)
        this.$data.blogs = existingEntries

        this.$data.lastBlogDocRef = fetchedEntries.lastDocReference
        this.$data.loading = false

        // If results are empty set finalHit to true
        if (!fetchedEntries.results.length) this.$data.finalHit = true
      }
    }
  },

  beforeCreate () {
    document.title = 'Articles - AmborJo'
  }
}
</script>
